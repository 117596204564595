import React from 'react';
import './Footer.css';

function Footer() {
  return (
    <div className='footer-container'>
        <div className='footer-link-wrapper'>
          <h2>Contatos</h2>
          <div className='footer-link-items'>
            <div><a href="mailto:concetinasegurancalagos@gmail.com" className="footer-mail"><i className="fas fa-envelope contact-icon"> </i> concetinasegurancalagos@gmail.com</a></div> 
            <div><a href="https://wa.me/5521972086416" target="_blank" rel="noreferrer" className="footer-whatsapp"><i className="fab fa-whatsapp contact-icon"> </i> (21) 97208-6416 </a></div>
          </div>
          <div className="footer-link-items-centered">
            Rua Antônio Lopes Fontoura 15 Flamengo - Maricá - CEP 24.900-435
          </div>
          <div className="footer-link-items-centered">
            Instalamos em todo Estado do Rio de Janeiro
          </div>
          <div className="footer-copyright">
            <div><i className="far fa-copyright"></i> 2021 Concertina Segurança.</div>
            <div><i className="fas fa-code"></i> Desenvolvido por <a href="https://www.linkedin.com/in/gabriel-monteiro-b8a860180/" target="_blank" rel="noreferrer">Gabriel Monteiro</a></div>
          </div>
        </div>
    </div>
  );
}

export default Footer;
