import React from "react"
import { Container } from "reactstrap"
import ImageCarousel from "../components/ImageCarousel"
import banner0 from "../assets/images/banner_0.jpg"
import banner1 from "../assets/images/banner_1.jpg"
import banner2 from "../assets/images/banner_2.jpg"
import banner3 from "../assets/images/banner_3.jpg"
import banner4 from "../assets/images/banner_4.jpg"
import banner5 from "../assets/images/banner_5.jpg"
import banner6 from "../assets/images/banner_6.jpg"
import banner7 from "../assets/images/banner_7.jpg"
import banner8 from "../assets/images/banner_8.jpg"
import banner9 from "../assets/images/banner_9.jpg"
import banner10 from "../assets/images/banner_10.jpg"
import banner11 from "../assets/images/banner_11.jpg"
import banner12 from "../assets/images/banner_12.jpg"
import banner13 from "../assets/images/banner_13.jpg"
import banner14 from "../assets/images/banner_14.jpg"
import banner15 from "../assets/images/banner_15.jpg"
import banner16 from "../assets/images/banner_16.jpg"

import "./Home.css"
import { MapContainer, Marker, Popup, TileLayer } from "react-leaflet"

const Home = () => {
    
    const position = [-22.915928, -42.810871]
    const items = [
        {
            src: banner0,
            altText: 'Banner 0',
        },
        {
            src: banner1,
            altText: 'Banner 1',
        },
        {
            src: banner2,
            altText: 'Banner 2',
        },
        {
            src: banner3,
            altText: 'Banner 3',
        },
        {
            src: banner4,
            altText: 'Banner 4',
        },
        {
            src: banner5,
            altText: 'Banner 5',
        },
        {
            src: banner6,
            altText: 'Banner 6',
        },
        {
            src: banner7,
            altText: 'Banner 7',
        },
        {
            src: banner8,
            altText: 'Banner 8',
        },
        {
            src: banner9,
            altText: 'Banner 9',
        },
        {
            src: banner10,
            altText: 'Banner 10',
        },
        {
            src: banner11,
            altText: 'Banner 11',
        },
        {
            src: banner12,
            altText: 'Banner 12',
        },
        {
            src: banner13,
            altText: 'Banner 13',
        },
        {
            src: banner14,
            altText: 'Banner 14',
        },
        {
            src: banner15,
            altText: 'Banner 15',
        },
        {
            src: banner16,
            altText: 'Banner 16',
        },
    ]
    
    return (
        <Container className="home-container">
            <ImageCarousel 
                items ={items}
            />

            <div className="texto-concertina">
                <h2 className="header-concertina">O que é Concertina ?</h2>
                <article>
                    No Brasil, a utilização de barreiras de segurança laminadas (mundialmente conhecida como concertinas, podendo ser chamada de ouriço, arame 
                    farpado moderno, espertina, navalha, etc.) com sua forma espiralada ou planas com lâminas de quatro pontas cortantes, pontiagudas e penetrantes 
                    substitui com inúmeras vantagens as cercas elétricas (dispensando manutenção e gastos de energia), como locais com plantas, telhados, locais 
                    baixos, etc. Comparadas com o ultrapassado arame farpado de fácil destruição, nos diferenciando pela resistência e qualidade de nossos produtos 
                    em GALVALUME (aço galvanizado com Alumínio), aço galvanizado, inox e material com pintura (com uma grande variedades de cores).
                </article>
            </div>

            <div className="mapa-container">
                <h2 className="header-concertina">Onde nos encontrar?</h2>
                <MapContainer 
                    className={"mapa"} 
                    center={position} 
                    zoom={27}
                    zoomControl={false}
                >
                    <TileLayer
                        attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    />        
                    <Marker
                        position={position}>
                        <Popup>
                            Rua Antônio Lopes Fontoura 15 Flamengo - Maricá
                        </Popup>
                    </Marker>
                </MapContainer>
            </div>
        </Container>
    )
}

export default Home