import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import {Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap"
import './Navbar.css';
import logo from "../assets/images/logo_editada.png"

function Navbar() {
  const [click, setClick] = useState(false);
  const [dropdownOpen, setOpenDropdown] = useState(false);
  const toggle = () => setOpenDropdown(prevState => !prevState);

  const handleClick = () => {
    if(click){
      setOpenDropdown(false)
    }
    setClick(!click);
  }
  const closeMobileMenu = () => {
    setClick(false);
    setOpenDropdown(false);
  };

  return (
    <>
      <nav className='navbar'>
        <div className='navbar-container'>
          <Link to='/' className='navbar-logo' onClick={closeMobileMenu}>
            <img height={120} src={logo} alt="logo"></img>
          </Link>
          <div className='menu-icon' onClick={handleClick}>
            <i className={click ? 'fas fa-times' : 'fas fa-bars'} />
          </div>
          <ul className={click ? 'nav-menu active' : 'nav-menu'}>
            <li className='nav-item'>
              <Link to='/' className='nav-links' onClick={closeMobileMenu}>
                Pagina Inicial
              </Link>
            </li>
            <li className='nav-item'>
              <Dropdown isOpen={dropdownOpen} toggle={toggle} className="nav-dropdown">
                <DropdownToggle className='nav-toggle-button'>
                  Produtos <i className={dropdownOpen ?"fas fa-angle-up" : "fas fa-angle-down"}></i>
                </DropdownToggle>

                <DropdownMenu className="nav-toggle-menu">
                  <Link to='/lanca' className='nav-toggle-link' onClick={closeMobileMenu}>
                    <DropdownItem className="nav-toggle-item">
                      Lança
                    </DropdownItem>
                  </Link>
                  <Link to='/cerca-eletrica' className='nav-toggle-link' onClick={closeMobileMenu}>
                    <DropdownItem className="nav-toggle-item">
                      Cerca Elétrica
                    </DropdownItem>
                  </Link>
                  <Link to='/rede-laminada' className='nav-toggle-link' onClick={closeMobileMenu}>
                    <DropdownItem className="nav-toggle-item">
                      Rede Laminada
                    </DropdownItem>
                  </Link>
                  <Link to='/concertina-plana' className='nav-toggle-link' onClick={closeMobileMenu}>
                    <DropdownItem className="nav-toggle-item">
                      Concertina Plana 
                    </DropdownItem>
                  </Link>
                  <Link to='/concertina-dupla-clipada' className='nav-toggle-link' onClick={closeMobileMenu}>
                    <DropdownItem className="nav-toggle-item">
                      Concertina Dupla Clipada 
                    </DropdownItem>
                  </Link>
                </DropdownMenu>
              </Dropdown>
            </li>
            <li className="nav-item">
                <a className="nav-links nav-whatsapp" href="https://wa.me/5521972086416" target="_blank" rel="noreferrer">
                   Contato <i className="fab fa-whatsapp nav-contact-icon"> </i></a>
            </li>
          </ul>
        </div>
      </nav>
    </>
  );
}

export default Navbar;
